// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBX-Wm-i-8SMiPUOBS7ESuRfBEJz2hFtx8",
  authDomain: "disc-dye-tracker.firebaseapp.com",
  projectId: "disc-dye-tracker",
  storageBucket: "disc-dye-tracker.appspot.com",
  messagingSenderId: "447283534831",
  appId: "1:447283534831:web:fe97f8250c0dbb2d82341a",
  measurementId: "G-VEMR615XLW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics};