import React, { useState } from 'react';
import '../App.css';

const DiscItem = ({
  disc,
  handleEditNotes,
  editingNotesId,
  updatedNotes,
  setUpdatedNotes,
  handleSaveNotes,
  setEditingNotesId, // Make sure this is passed as a prop
  isFinished,
  hoursRemaining,
  handleDeleteDisc,
  handleEditDiscName,
  editingDiscId,
  updatedDiscName,
  setUpdatedDiscName,
  handleSaveDiscName,
  setEditingDiscId, // Make sure this is passed as a prop
  uploadImage,
  uploadFinishedImage,
  isUploading,
  uploadProgress,
  setUploadProgress,
  user
}) => {
  const [beforeImageProgress, setBeforeImageProgress] = useState(0);
  const [patternImageProgress, setPatternImageProgress] = useState(0);
  const [finishedImageProgress, setFinishedImageProgress] = useState(0);
  const [stencilImageProgress, setStencilImageProgress] = useState(0);

  const confirmReplaceImage = (imageType, discId, setImageProgress) => {
    //console.log(`Trying to replace ${imageType} for disc ID: ${discId}`); // Add this line
    if (window.confirm('Are you sure you want to replace this image?')) {
      const fileInput = document.getElementById(`${imageType}-upload-${discId}`);
      //console.log(fileInput); // Log to check if the file input element is found
      if (fileInput) {
        fileInput.click();
      }
    }
  };
  

  return (
    <div className="disc-item border p-3 mb-4 col-11 rounded">
      {/* Finished Status */}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type="checkbox"
            checked={isFinished || hoursRemaining <= 0} // Automatically mark as finished if hours remaining is 0 or less
            readOnly
            style={{ width: '20px', height: '20px', marginRight: '10px' }}
          />
          {isFinished || hoursRemaining <= 0 ? (
            <span>Finished</span>
          ) : (
            <span>{hoursRemaining > 0 ? `${hoursRemaining} hours remaining` : 'Finished'}</span>
          )}
        </div>
      </div>

      {/* Disc Name Section */}
      <div className="disc-section mt-3">
        {editingDiscId === disc.id ? (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flexGrow: 1 }}>
            <input
              type="text"
              value={updatedDiscName || ''}
              onChange={(e) => setUpdatedDiscName(e.target.value)}
              className="form-control mb-3"
              style={{ textAlign: 'center', maxWidth: '80%' }}
            />
            <div className="d-flex justify-content-center mt-2">
              <button onClick={() => handleSaveDiscName(disc.id)} className="btn btn-success me-2">
                Save
              </button>
              <button onClick={() => setEditingDiscId(null)} className="btn btn-secondary">
                Back
              </button>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-between align-items-center">
            <h2 style={{ flexGrow: 1, textAlign: 'center' }}>{disc.discName}</h2>
            <button onClick={() => handleEditDiscName(disc.id, disc.discName)} className="btn btn-outline-secondary btn-sm">
              ✏️
            </button>
          </div>
        )}
      </div>

      {/* Notes Section */}
      <div className="disc-section mt-3">
        {editingNotesId === disc.id ? (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flexGrow: 1 }}>
            <textarea
              value={updatedNotes || ''}
              onChange={(e) => setUpdatedNotes(e.target.value)}
              rows="3"
              className="form-control col-10 mb-3"
              style={{ textAlign: 'center', maxWidth: '80%' }}
            />
            <div className="d-flex justify-content-center mt-2">
              <button onClick={() => handleSaveNotes(disc.id)} className="btn btn-success me-2">
                Save Notes
              </button>
              <button onClick={() => setEditingNotesId(null)} className="btn btn-secondary">
                Back
              </button>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-between align-items-center">
            <p style={{ flexGrow: 1, textAlign: 'center' }}>Notes: {disc.notes}</p>
            <button onClick={() => handleEditNotes(disc.id, disc.notes)} className="btn btn-outline-secondary btn-sm edit-btn">
              ✏️
            </button>
          </div>
        )}
      </div>

      {/* Time Section */}
      {/* If there is not a before image it is just a finished image of the disc and there is not any times to show*/ }
      {disc.beforeImage !== "" && (
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <p>Start Time: {new Date(disc.startTime.seconds * 1000).toLocaleString()}</p>
        <p>Finish Time: {new Date(disc.finishedTime.seconds * 1000).toLocaleString()}</p>
        <p>Dye Time: {disc.hours} hours</p>
      </div>
      )}

{/* Image Section */}
<div
  className={`d-grid gap-3 mt-3 ${disc.stencilImage && disc.stencilImage !== '' ? 'grid-2x2' : 'grid-1x3'}`}
  style={{
    gridTemplateColumns: disc.stencilImage && disc.stencilImage !== '' ? '1fr 1fr' : 'repeat(3, 1fr)',
    gridTemplateRows: disc.stencilImage && disc.stencilImage !== '' ? '1fr 1fr' : 'auto',
    gap: '10px',
  }}
>
  {/* Before Image */}
  {disc.beforeImage !== "" && (
  <div className="d-flex flex-column align-items-center rounded">
    <h3>Original</h3>
    <img
      src={disc.beforeImage.url}
      alt="Before Disc"
      className="img-fluid rounded"
      loading="lazy"
      style={{
        width: '100%',
        maxWidth: '300px',
        height: 'auto',
        objectFit: 'contain',
        borderRadius: '8px',
      }}
    />
    {beforeImageProgress > 0 && beforeImageProgress < 100 && (
      <div className="mt-2 d-flex align-items-center justify-content-center">
        <div className="spinner me-2"></div>
        <p className="mb-0">Loading...</p>
      </div>
    )}
    <button
      className="btn btn-outline-secondary btn-sm mt-2"
      style={{ width: 'auto', padding: '5px 10px' }}
      onClick={() => confirmReplaceImage('beforeImage', disc.id, setBeforeImageProgress)}
    >
      ✏️
    </button>
    <input
      id={`beforeImage-upload-${disc.id}`}
      type="file"
      accept="image/*"
      onChange={(e) => uploadImage(disc.id, 'beforeImage', e.target.files[0], setBeforeImageProgress)}
      className="form-control mt-2"
      style={{ display: 'none' }}
    />
  </div>
  )}

  {/* Stencil Image */}
  {disc.stencilImage && disc.stencilImage !== '' && (
    <div className="d-flex flex-column align-items-center rounded">
      <h3>Stencil</h3>
      <img
        src={disc.stencilImage.url}
        alt="Stencil"
        className="img-fluid rounded"
        loading="lazy"
        style={{
          width: '100%',
          maxWidth: '300px',
          height: 'auto',
          objectFit: 'contain',
          borderRadius: '8px',
        }}
      />
    {stencilImageProgress > 0 && stencilImageProgress < 100 && (
      <div className="mt-2 d-flex align-items-center justify-content-center">
        <div className="spinner me-2"></div>
        <p className="mb-0">Loading...</p>
      </div>
    )}

      <button
        className="btn btn-outline-secondary btn-sm mt-2"
        style={{ width: 'auto', padding: '5px 10px' }}
        onClick={() => confirmReplaceImage('stencilImage', disc.id, setStencilImageProgress)}
      >
        ✏️
      </button>
      <input
        id={`stencilImage-upload-${disc.id}`}
        type="file"
        accept="image/*"
        onChange={(e) => uploadImage(disc.id, 'stencilImage', e.target.files[0], setStencilImageProgress)}
        className="form-control mt-2"
        style={{ display: 'none' }}
      />
    </div>
  )}


  {/* Pattern Image */}
  {disc.patternImage !== "" && (
  <div className="d-flex flex-column align-items-center rounded">
    <h3>Pattern</h3>
    <img
      src={disc.patternImage.url}
      alt="Pattern"
      className="img-fluid rounded"
      loading="lazy"
      style={{
        width: '100%',
        maxWidth: '300px',
        height: 'auto',
        objectFit: 'contain',
        borderRadius: '8px',
      }}
    />
    {patternImageProgress > 0 && patternImageProgress < 100 && (
      <div className="mt-2 d-flex align-items-center justify-content-center">
        <div className="spinner me-2"></div>
        <p className="mb-0">Loading...</p>
      </div>
    )}
    <button
      className="btn btn-outline-secondary btn-sm mt-2"
      style={{ width: 'auto', padding: '5px 10px' }}
      onClick={() => confirmReplaceImage('patternImage', disc.id, setPatternImageProgress)}
    >
      ✏️
    </button>
    <input
      id={`patternImage-upload-${disc.id}`}
      type="file"
      accept="image/*"
      onChange={(e) => uploadImage(disc.id, 'patternImage', e.target.files[0], setPatternImageProgress)}
      className="form-control mt-2"
      style={{ display: 'none' }}
    />
  </div>
  )}

  {/* Finished Image */}
  {disc.beforeImage !== "" && disc.patternImage !== "" && (
    <div className="d-flex flex-column align-items-center rounded">
      <h3>Finished</h3>
      {disc.finishedImage ? (
        <>
          <img
            src={disc.finishedImage.url}
            alt="Finished Disc"
            className="img-fluid rounded"
            loading="lazy"
            style={{
              width: '100%',
              maxWidth: '300px',
              height: 'auto',
              objectFit: 'contain',
              borderRadius: '8px',
            }}
          />
       
          <button
            className="btn btn-outline-secondary btn-sm mt-2"
            style={{ width: 'auto', padding: '5px 10px' }}
            onClick={() => confirmReplaceImage('finishedImage', disc.id, setFinishedImageProgress)}
          >
            ✏️
          </button>
          <input
            id={`finishedImage-upload-${disc.id}`}
            type="file"
            accept="image/*"
            onChange={(e) => uploadImage(disc.id, 'finishedImage', e.target.files[0], setFinishedImageProgress)}
            className="form-control mt-2"
            style={{ display: 'none' }}
          />
        </>
      ) : (
        <>
          <p>Upload Finished Image:</p>
          <input
            id={`finishedImage-upload-${disc.id}`}
            type="file"
            accept="image/*"
            onChange={(e) => uploadImage(disc.id, 'finishedImage', e.target.files[0], setFinishedImageProgress)}
            className="form-control mt-2 col-2"
          />
        </>
      )}
    </div>
  )}


</div>

{disc.beforeImage === "" && disc.patternImage === "" && (
  <div className="d-flex flex-column align-items-center text-center rounded" style={{ maxWidth: '300px', margin: '0 auto', minWidth: '400px' }}>
    <h3>Finished</h3>
    {disc.finishedImage ? (
      <>
        <img
          src={disc.finishedImage.url}
          alt="Finished Disc"
          className="img-fluid rounded"
          loading="lazy"
          style={{
            width: '100%',
            maxWidth: '300px',
            height: 'auto',
            objectFit: 'contain',
            borderRadius: '8px',
          }}
        />
        <button
          className="btn btn-outline-secondary btn-sm mt-2"
          style={{ width: 'auto', padding: '5px 10px' }}
          onClick={() => confirmReplaceImage('finishedImage', disc.id, setFinishedImageProgress)}
        >
          ✏️
        </button>
        <input
          id={`finishedImage-upload-${disc.id}`}
          type="file"
          accept="image/*"
          onChange={(e) => uploadImage(disc.id, 'finishedImage', e.target.files[0], setFinishedImageProgress)}
          className="form-control mt-2"
          style={{ display: 'none' }}
        />
      </>
    ) : (
      <>
        <p>Upload Finished Image:</p>
        <input
          id={`finishedImage-upload-${disc.id}`}
          type="file"
          accept="image/*"
          onChange={(e) => uploadImage(disc.id, 'finishedImage', e.target.files[0], setFinishedImageProgress)}
          className="form-control mt-2"
        />
      </>
    )}
      {finishedImageProgress > 0 && finishedImageProgress < 100 && (
        <div className="mt-3 d-flex align-items-center justify-content-center">
          <div className="spinner me-2"></div>
          <p className="mb-0">Loading...</p>
        </div>
      )}
  </div>
)}



    {isUploading && (
        <div className="d-flex flex-column align-items-center mb-3 pt-2">
          <div className="spinner mb-2"></div>
          <p className="mb-0">Uploading Image... {uploadProgress}%</p>
        </div>
      )}

      {/* Delete Button */}
      <button onClick={() => handleDeleteDisc(disc.id)} className="btn btn-danger mt-3">
        Delete Disc
      </button>
    </div>
  );
};

export default DiscItem;
