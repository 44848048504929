import React, { useState } from 'react';
import imageCompression from 'browser-image-compression';
import { getFirestore, doc, setDoc, Timestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { app } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import '../App.css';


function DiscForm({ user, fetchDiscs,  getPresignedUrl,
uploadToS3, uploadFinishedImage  }) {
  const [discName, setDiscName] = useState("");
  const [beforeImage, setBeforeImage] = useState(null);
  const [patternImage, setPatternImage] = useState(null);
  const [isCustomTime, setIsCustomTime] = useState(true);
  const [customTime, setCustomTime] = useState("");
  const [startTime, setStartTime] = useState(new Date());
  const [stencilChecked, setStencilChecked] = useState(false);
  const [singleImage, setSingleImage] = useState(false);
  const [stencilImage, setStencilImage] = useState(null);
  const [finishedImage , setFinishedImage] = useState(null)
  const [hours, setHours] = useState("");
  const [notes, setNotes] = useState("");
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadMessage, setUploadMessage] = useState('');
  const db = getFirestore(app);
  const storage = getStorage(app);
  const navigate = useNavigate();

  const handleFormSubmit = async () => {
    if (!singleImage && (!discName || !beforeImage || !patternImage || !hours)) {
      alert("Please fill in all fields.");
      return;
    }

    if (singleImage && (!discName || !finishedImage)) {
      alert("Please fill in all fields.");
      return;
    }
  
  
    setLoading(true);
    let chosenStartTime = startTime;
    setLoading(true)
    setUploadMessage('Uploading Disc...');
  
    if (customTime) {
      const parsedCustomTime = new Date(customTime);
      if (!isNaN(parsedCustomTime.getTime())) {
        chosenStartTime = parsedCustomTime;
      } else {
        alert("Invalid custom time value. Using current start time.");
      }
    }
  
    const discRef = doc(db, "Discs", user.uid + "_" + Date.now());
    const discId = discRef.id;
  
    try {
      if(!singleImage){
      const uploadImageToS3 = async (imageFile, imageName) => {
        const mimeType = imageFile.type;
        // Fetch pre-signed URL
        const presignedUrl = await getPresignedUrl(imageName, mimeType, discId, user);
        const s3Url = await uploadToS3(imageFile, presignedUrl);
        return {
          url: s3Url,
          // Set expiration date to one week in the past
          expiration: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString(),
        };
      };
  
 
  
      // Upload images to S3
      const beforeImageObj = await uploadImageToS3(beforeImage, `${user.uid}/${discId}/beforeImage.jpg`);
      const patternImageObj = await uploadImageToS3(patternImage, `${user.uid}/${discId}/patternImage.jpg`);
      let stencilImageObj = null;
      if (stencilChecked && stencilImage) {
        stencilImageObj = await uploadImageToS3(stencilImage, `${user.uid}/${discId}/stencilImage.jpg`);
      }
  
      const finishedTime = new Date(chosenStartTime);
      finishedTime.setHours(finishedTime.getHours() + parseInt(hours));
  
      // Store the disc data in Firestore (without pre-signed URLs)
      await setDoc(discRef, {
        discName,
        beforeImage: beforeImageObj,
        patternImage: patternImageObj,
        stencilImage: stencilImageObj,
        startTime: Timestamp.fromDate(chosenStartTime),
        finishedTime: Timestamp.fromDate(finishedTime),
        finishedImage: null,
        userid: user.uid,
        isFinished: false,
        hours,
        notes,
      });
    } else if(singleImage){
      console.log("uploading finished image")
      uploadFinishedImage(discId, finishedImage)

      await setDoc(discRef, {
        discName,
        beforeImage: "",
        patternImage: "",
        stencilImage: "",
        startTime: Timestamp.fromDate(chosenStartTime),
        finishedTime: Timestamp.fromDate(new Date(chosenStartTime)),
        finishedImage: null,
        userid: user.uid,
        isFinished: false,
        hours,
        notes,
      });
    }
  
      //console.log("Disc entry created successfully with pre-signed URLs!");
  
      // Generate pre-signed URLs for the disc
      if (!singleImage){
        const presignedUrlResult = await generatePresignedUrlForDisc(discId);
      }

      // Add a small delay before fetching to ensure the URLs are available
      await new Promise(resolve => setTimeout(resolve, 1000));
  
      // Fetch the newly added disc again to ensure the state is updated with the pre-signed URLs
      await fetchDiscs(3, true); // Make sure to refetch discs to get updated URLs
  
      // Add a delay before navigating to ensure all pre-signed URLs are fetched and updated
      setTimeout(() => {
        navigate('/home');
      }, 1000); // Add a 1-second delay to ensure data is fetched
  
    } catch (error) {
      console.error("Error saving disc:", error);
      alert(error.message);
    } finally {
      setLoading(false);
      setUploadMessage('');
    }
  };
  

  const generatePresignedUrlForDisc = async (discId) => {
    const functions = getFunctions(app); // Use Firebase Functions
    const generateUrls = httpsCallable(functions, 'generatePresignedUrlsForDisc');
    const result = await generateUrls({ discId }); // Pass the discId to the function

    // If successful, refetch discs to ensure URLs are up-to-date before rendering
    if (result && result.data.success) {
    // console.log(`Pre-signed URLs generated for disc: ${discId}`);
      await fetchDiscs(3, true); // Pass `true` to refresh discs after update
    }

    return result;
  };

  return (
    <div className="container my-5">
      <div className="row justify-content-center">
        <div className="col-lg-8 col-md-10 col-sm-12">
          <div className="card p-4 shadow" style={{ backgroundColor: '#282c34' }}>
            <h2 className="text-center mb-4 text-light">Add a New Disc</h2>

            {/* Single Image Checkbox */}
            <div className="form-group mb-3 text-light">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="singleImage"
                  checked={singleImage}
                  onChange={() => setSingleImage(!singleImage)}
                />
                <label
                  className="form-check-label"
                  htmlFor="singleImage"  // Ensure this matches the checkbox id
                >
                  Already Dyed Disc
                </label>
              </div>
            </div>



            {/* Disc Name */}
            <div className="form-group mb-3 text-light">
              <label>Disc Name:</label>
              <input
                type="text"
                value={discName}
                onChange={(e) => setDiscName(e.target.value)}
                className="form-control"
                placeholder="Enter Disc Name"
              />
            </div>

            {/* Before Image */}
            {!singleImage && (
             <div className="form-group mb-3 text-light">
                <label>Before Dyeing:</label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => setBeforeImage(e.target.files[0])}
                  className="form-control"
                />
              </div>             
            )}


            {/* Stencil/Vinyl Checkbox */}
            {!singleImage && (
            <div className="form-group mb-3 text-light">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="stencilCheck"
                  checked={stencilChecked}
                  onChange={() => setStencilChecked(!stencilChecked)}
                />
                <label className="form-check-label" htmlFor="stencilCheck">
                  Stencil
                </label>
              </div>
            </div>
            )}

            {/* Conditional Stencil Image Input */}
            
            {stencilChecked && !singleImage && (
              <div className="form-group mb-3 text-light">
                <label>Stencil Image:</label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => setStencilImage(e.target.files[0])}
                  className="form-control"
                />
              </div>
            )}

            {/* Pattern Image */}
            
            {!singleImage && (
            <div className="form-group mb-3 text-light">
              <label>Dye Pattern:</label>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => setPatternImage(e.target.files[0])}
                className="form-control"
              />
            </div>
            )}

                        
            {singleImage && (
            <div className="form-group mb-3 text-light">
              <label>Disc Image:</label>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => setFinishedImage(e.target.files[0])}
                className="form-control"
              />
            </div>
            )}

          {/* Start Time */}
          {!singleImage && (
            <div className="form-group mb-3 text-light">
              <label>Start Time:</label>
              <div className="d-flex align-items-center">
                <button
                  onClick={() => {
                    setStartTime(new Date());
                    setIsCustomTime(false); // Hide custom time input
                  }}
                  className="btn btn-primary me-3"
                >
                  Set to Now
                </button>
                <span>{startTime.toLocaleString()}</span>
              </div>
            </div>
          )}

          {/* Custom Start Time Input */}
          {isCustomTime && !singleImage && (
            <div className="form-group mb-3 text-light">
              <label htmlFor="customTime">Custom Start Time:</label>
              <input
                type="datetime-local"
                id="customTime"
                value={customTime}
                onChange={(e) => {
                  const selectedTime = new Date(e.target.value);
                  setCustomTime(e.target.value);
                  setStartTime(selectedTime); // Update startTime with the custom time
                  setIsCustomTime(true); // Show the custom time input
                }}
                className="form-control"
              />
            </div>
          )}

          {/* Checkbox to enable custom start time after setting to "Now" */}
          {!isCustomTime && !singleImage && (
            <div className="form-group mb-3 text-light">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="setCustomTimeCheckbox"
                  checked={isCustomTime}
                  onChange={(e) => setIsCustomTime(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="setCustomTimeCheckbox">
                  Set different start time
                </label>
              </div>
            </div>
          )}


            {/* Dye Time */}
            {!singleImage && (
            <div className="form-group mb-3 text-light">
              <label>Dye Time (Hours):</label>
              <select
                value={hours}
                onChange={(e) => setHours(e.target.value)}
                className="form-control"
              >
                <option value="" disabled>
                  Select an option
                </option>
                {Array.from({ length: 36 }, (_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
              </select>
            </div>
            )}

            {/* Notes */}
            <div className="form-group mb-3 text-light">
              <label>Notes:</label>
              <textarea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                className="form-control"
                placeholder="Add any notes..."
                rows="4"
              />
            </div>

            {loading && (
              <div className="mb-3 text-light d-flex justify-content-center">
                <div className="d-flex align-items-center">
                  <div className="spinner me-2"></div> {/* Spinner next to message */}
                  <p className="mb-0">{uploadMessage}</p>
                </div>
              </div>
            )}


            {/* Submit Button */}
            {!loading && (
              <button onClick={handleFormSubmit} className="btn btn-success w-100 text-light">
                Submit
              </button>
            )}

          </div>
        </div>
      </div>
    </div>
  );
}

export default DiscForm;
