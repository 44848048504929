import React, { useState, useEffect } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged, signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail, } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { app } from '../firebase'; // Import your Firebase configuration
import example1 from '../images/example1.png';
import example2 from '../images/example2.png';
import google from '../images/google.png';
import { ListGroup, Container, Row, Col, Button } from 'react-bootstrap';
import disc from '../images/disc.png';
import dye from '../images/dye.png';
import finishedDisc from '../images/finishedDisc.png';
import axios from 'axios';
import { Link } from 'react-router-dom';



function Login({ onUserSignIn, onUserSignOut }) {
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const auth = getAuth(app);
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const provider = new GoogleAuthProvider();
  const db = getFirestore(app);
  const isButtonDisabled = !recaptchaVerified || loading;

  // Clear error message when typing in email or password
  useEffect(() => {
    setErrorMessage('');
  }, [email, password]);


  // Function to handle Google Sign-In
  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const loggedInUser = result.user;
      setUser(loggedInUser);
      onUserSignIn(loggedInUser);

      // Check if the user exists in Firestore
      const userRef = doc(db, 'Users', loggedInUser.uid);
      const docSnap = await getDoc(userRef);

      if (!docSnap.exists()) {
        await setDoc(userRef, {
          email: loggedInUser.email,
          displayName: loggedInUser.displayName,
          createdAt: new Date(),
        });
        console.log('User added to Firestore');
      } else {
        console.log('User already exists in Firestore');
      }
    } catch (error) {
      console.error('Error during Google sign-in:', error);
    }
  };

  const signInWithEmail = async () => {
    if (!recaptchaVerified) {
      setErrorMessage('Please complete the reCAPTCHA to continue.');
      return;
    }
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      const loggedInUser = result.user;
      setUser(loggedInUser);
      onUserSignIn(loggedInUser);
      console.log('Signed in with email and password');
      setErrorMessage('');
    } catch (error) {
      console.error('Error during email sign-in:', error);
      if (error.code === 'auth/wrong-password') {
        setErrorMessage('Incorrect password. Please try again.');
      } else if (error.code === 'auth/user-not-found') {
        setErrorMessage('No user found with this email. Please sign up.');
      } else {
        setErrorMessage('Error during login. Please try again.');
      }
    }
  };

    // Verify the reCAPTCHA token
    const verifyRecaptchaToken = async (token, action) => {
      try {
        setLoading(true);
        const firebaseFunctionUrl = 'https://us-central1-disc-dye-tracker.cloudfunctions.net/verifyRecaptchaToken';

        const response = await axios.post(firebaseFunctionUrl, { token });
        if (response.data.success) {
          setRecaptchaVerified(true);
          setLoading(false);
          action(); // Call the intended action (login, signup, or forgot password)
        } else {
          setRecaptchaVerified(false);
          setErrorMessage('reCAPTCHA verification failed. Please try again.');
          setLoading(false);
        }
      } catch (error) {
        console.error('reCAPTCHA verification error:', error);
        setRecaptchaVerified(false);
        setErrorMessage('Error verifying reCAPTCHA. Please try again.');
        setLoading(false);
      }
    };
    


    const signUpWithEmail = async () => {
      if (!recaptchaVerified) {
        setErrorMessage('Please complete the reCAPTCHA to continue.');
        return;
      }
      try {
        const result = await createUserWithEmailAndPassword(auth, email, password);
        const newUser = result.user;
        setUser(newUser);
        onUserSignIn(newUser);
  
        const userRef = doc(db, 'Users', newUser.uid);
        await setDoc(userRef, {
          email: newUser.email,
          createdAt: new Date(),
        });
        console.log('User signed up and added to Firestore');
        setErrorMessage('');
      } catch (error) {
        console.error('Error during sign-up:', error);
        if (error.code === 'auth/email-already-in-use') {
          setErrorMessage('The email is already in use. Please use a different email or log in.');
        } else if (error.code === 'auth/weak-password') {
          setErrorMessage('Password is too weak. Please use a stronger password.');
        } else {
          setErrorMessage('Error during sign-up. Please try again.');
        }
      }
    };

    // Wrapper function to handle reCAPTCHA before calling the desired action
    const handleRecaptchaAndAction = (action) => {
      if (window.grecaptcha) {
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute('6LftSXoqAAAAADr7mnh7oEpB33RDGxWosElO3lls', { action: 'login' })
            .then((token) => {
              verifyRecaptchaToken(token, action);
            })
            .catch((error) => {
              console.error('Error during reCAPTCHA execution:', error);
            });
        });
      } else {
        console.error('reCAPTCHA script not loaded properly');
      }
    };

    // Function to handle Password Reset
    const handleForgotPassword = async () => {
      if (!recaptchaVerified) {
        setErrorMessage('Make sure you entered a valid email');
        return;
      }
      try {
        await sendPasswordResetEmail(auth, email);
        console.log('Password reset email sent');
        setErrorMessage('Password reset email sent');
      } catch (error) {
        console.error('Error sending password reset email:', error);
        setErrorMessage('Error sending password reset email. Please try again.');
      }
    };
  // Check if the user is already signed in
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        onUserSignIn(currentUser);
      }
    });
    return () => unsubscribe();
  }, [auth, onUserSignIn]);

  if (user) {
    return (
      <div className="text-center">
        <h1>Welcome, {user.displayName || user.email}!</h1>
        <button
          className="btn btn-danger mt-3"
          onClick={() => auth.signOut().then(() => {
            setUser(null);
            onUserSignOut();
          })}
        >
          Sign Out
        </button>
      </div>
    );
  }

  return (
    <div className="text-center d-flex flex-column align-items-center" style={{ overflowX: 'hidden' }}>
      <h1 className="display-1 text-white col-10 mx-auto">Disc Golf Dye Tracker</h1>
      <h2 className="display-6 text-white col-10 mx-auto">Track your disc dyeing progress</h2>
      <h3 className="display-6 text-white col-10 mx-auto">Upload Pictures of your disc before dyeing, your dye pattern, and your finished dyed disc</h3>
      <button
        onClick={signInWithGoogle}
        className="btn btn-light col-10 col-lg-2 mx-auto mt-2"
      >
        <img
          src={google}
          alt="Google Logo"
          loading="lazy"
          style={{ maxHeight: '25px',
                   paddingRight: '10px',}}
        />
        Sign Up/Login with Google
      </button>
      <div className="email-auth-container mt-4 col-7 col-md-8 col-lg-3">
        <p className="small mb-2">{errorMessage ? <span className="text-danger small">{errorMessage}</span> : 'Log in/Sign up'}</p>
        <div className="form-group mb-3">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-control"
            placeholder="Email"
          />
        </div>
        <div className="form-group mb-3">
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="form-control"
            placeholder="Password"
          />
        </div>
        <div className="d-flex justify-content-center">
          <button className="btn btn-primary col-8 col-md-5 mx-2"  onClick={() => handleRecaptchaAndAction(signInWithEmail)} disabled={loading}>Log In</button>
          <button className="btn btn-success col-8 col-md-5 mx-2" onClick={() => handleRecaptchaAndAction(signUpWithEmail)} disabled={loading}>Sign Up</button>
        </div>
        <button className="btn btn-link mt-2"  onClick={() => handleRecaptchaAndAction(handleForgotPassword)} disabled={loading}>Forgot Password?</button>
      </div>

      {/* Image Row */}
      <div className="row justify-content-center mt-4 col-10 mx-auto">
        <div className="col-12 col-md-4 mb-3">
          <img
            src={example1}
            alt="Finished Disc Picture"
            className="img-fluid rounded"
            loading="lazy"
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'cover',
            }}
          />
        </div>
        <div className="col-12 col-md-4 mb-3">
          <img
            src={example2}
            alt="Finished Disc Picture"
            className="img-fluid rounded"
            loading="lazy"
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'cover',
            }}
          />
        </div>
      </div>

      <Container className="my-4 text-light" style={{ backgroundColor: '#343a40', padding: '20px', borderRadius: '8px' }}>
        <h2 className="text-center">How to dye disc golf discs</h2>
        <ListGroup as="ul" className="mt-3">
          <ListGroup.Item className="bg-dark text-light mb-3 border rounded">
            <Row className="align-items-center">
              <Col md={3} className="text-center">
                <h3>Clean the Disc</h3>
              </Col>
              <Col md={7} className="text-start">
                <p>Use disc soap to thoroughly clean the disc before dyeing.</p>
              </Col>
              <Col md={2} className="text-end">
                <img src={disc} alt="Clean disc" style={{ width: '100px', height: '100px', borderRadius: '8px' }} />
              </Col>
            </Row>
          </ListGroup.Item>

          <ListGroup.Item className="bg-dark text-light mb-3 border rounded">
            <Row className="align-items-center">
              <Col md={3} className="text-center">
                <h3>Prepare the Dye</h3>
              </Col>
              <Col md={7} className="text-start">
                <p>Place shaving cream on a round plate slightly larger than the disc. Add a small amount of water, then sprinkle Powdered dye evenly on top.</p>
              </Col>
              <Col md={2} className="text-end">
                <img src={dye} alt="Dye preparation" style={{ width: '100px', height: '100px', borderRadius: '8px' }} />
              </Col>
            </Row>
          </ListGroup.Item>

          <ListGroup.Item className="bg-dark text-light mb-3 border rounded">
            <Row className="align-items-center">
              <Col md={3} className="text-center">
                <h3>Apply the Disc</h3>
              </Col>
              <Col md={7} className="text-start">
                <p>Place the disc face down on the shaving cream mixture and let it sit for at least 12 hours at room temperature.</p>
              </Col>
              <Col md={2} className="text-end">
                <img src={finishedDisc} alt="Finished disc" style={{ width: '100px', height: '100px', borderRadius: '8px' }} />
              </Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>
      </Container>

      <Container className="my-4 text-light" style={{ backgroundColor: '#343a40', padding: '20px', borderRadius: '8px' }}>
        <h2 className="text-center">Disc Golf Dyeing Methods</h2>
        <ListGroup as="ul" className="mt-3">
          <ListGroup.Item className="bg-dark text-light mb-3 border rounded">
            <Row className="align-items-center">
              <Col md={3} className="text-center">
                <h3>Shaving Cream Bed Dyeing</h3>
              </Col>
              <Col md={9} className="text-start">
                <p>Uses a layer of shaving cream to create unique patterns as the dye settles over time.</p>
                <a href="https://www.dgputtheads.com/disc-golf-shaving-cream-dye" target="_blank" rel="noopener noreferrer" className="text-info">
                  Guide: Disc Dyeing with Shaving Cream
                </a>
              </Col>
            </Row>
          </ListGroup.Item>

          <ListGroup.Item className="bg-dark text-light mb-3 border rounded">
            <Row className="align-items-center">
              <Col md={3} className="text-center">
                <h3>Spin Dyeing</h3>
              </Col>
              <Col md={9} className="text-start">
                <p>A spinning tool is used to create circular or spiral patterns by applying dye as the disc rotates.</p>
                <a href="https://youtu.be/suqVoJTdTeE?si=OZvKyvYhlF83wJod" target="_blank" rel="noopener noreferrer" className="text-info">
                  Guide: How to Spin Dye a Disc
                </a>
              </Col>
            </Row>
          </ListGroup.Item>

          <ListGroup.Item className="bg-dark text-light mb-3 border rounded">
            <Row className="align-items-center">
              <Col md={3} className="text-center">
                <h3>Stencil Dyeing</h3>
              </Col>
              <Col md={9} className="text-start">
                <p>Involves cutting out stencils and applying them to the disc to dye specific shapes or designs.</p>
                <a href="https://youtu.be/PAS-CrJ9DOA?si=aUt2h5kblnV9feh9" target="_blank" rel="noopener noreferrer" className="text-info">
                  Guide: Disc Golf Stencil Dye Guide
                </a>
              </Col>
            </Row>
          </ListGroup.Item>

          <ListGroup.Item className="bg-dark text-light mb-3 border rounded">
            <Row className="align-items-center">
              <Col md={3} className="text-center">
                <h3>Lotion Bed Dyeing</h3>
              </Col>
              <Col md={9} className="text-start">
                <p>Lotion is used as a base to slow down dye absorption, creating softer patterns and swirls.</p>
                <a href="https://youtu.be/9YwH82hP26g?si=R96eAvLJA6m_zmpD" target="_blank" rel="noopener noreferrer" className="text-info">
                  Guide: Lotion Bed Disc Dyeing Tutorial
                </a>
              </Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>

        <h2 className="text-center mt-5">Types of Disc Golf Dyes</h2>
          <ListGroup as="ul" className="mt-3">
          <ListGroup.Item className="bg-dark text-light mb-3 border rounded">
            <Row className="align-items-center">
              <Col md={3} className="text-center">
                <h3>Pro Chemical & Dye</h3>
              </Col>
              <Col md={9} className="text-start">
              <p>Offers a range of dyes suitable for disc dyeing, providing high-quality options.</p>
                <a href="https://prochemicalanddye.com/disc-golf-disc-dyes/" target="_blank" rel="noopener noreferrer" className="text-info">
                  Buy on Pro Chemical & Dye's Website
                </a>
              </Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>

        <ListGroup as="ul" className="mt-3">
          <ListGroup.Item className="bg-dark text-light mb-3 border rounded">
            <Row className="align-items-center">
              <Col md={3} className="text-center">
                <h3>iDye Poly</h3>
              </Col>
              <Col md={9} className="text-start">
                <p>Specifically formulated for synthetic materials, iDye Poly is a popular choice for dyeing disc golf discs.</p>
                <a href="https://www.jacquardproducts.com/idye-poly" target="_blank" rel="noopener noreferrer" className="text-info">
                  Buy on Amazon
                </a>
              </Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>

        <ListGroup as="ul" className="mt-3">
          <ListGroup.Item className="bg-dark text-light mb-3 border rounded">
            <Row className="align-items-center">
              <Col md={3} className="text-center">
                <h3>Quick Coat Worm Dip</h3>
              </Col>
              <Col md={9} className="text-start">
                <p>Initially designed for plastic fishing lures, Quick Coat Worm Dip instantly changes the color of soft plastics to give you near limitless possibilities. Quick-Coat Worm Dip has a penetrating finish that dries in seconds. Colors can even be mixed to make your own custom colors.</p>
                <a href="https://truenorthdiscgolf.com/products/quick-coat-worm-dip?srsltid=AfmBOopagsZ19L6FIsYoM8Ar62RB4GLlUh1RytQ0vLq-Zx2xim9ECUVt" target="_blank" rel="noopener noreferrer" className="text-info">
                  Buy on True North Disc Golf.com
                </a>
              </Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>

      </Container>
      <footer className="bg-dark text-light w-100 d-flex justify-content-center align-items-center" style={{ height: '100px', backgroundColor: '#343a40' }}>
        <a href="mailto:Topcitysoftware@gmail.com" className="text-info ms-2">Contact me</a>
      </footer>


    </div>
    
  );
  
}

export default Login;
