import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import './App.css';
import Login from './components/Login';
import DiscForm from './components/DiscForm';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs, updateDoc, deleteDoc, doc, getDoc, addDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, listAll, deleteObject } from 'firebase/storage'; // Import storage functions
import { app } from './firebase';
import 'bootstrap/dist/css/bootstrap.min.css';
import DiscItem from './components/DiscItem';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import imageCompression from 'browser-image-compression';
import { getFunctions, httpsCallable } from 'firebase/functions';



function App() {
  const [user, setUser] = useState(null);
  const [discs, setDiscs] = useState([]);
  const [editingNotesId, setEditingNotesId] = useState(null);
  const [updatedNotes, setUpdatedNotes] = useState('');
  const [editingDiscId, setEditingDiscId] = useState(null);
  const [updatedDiscName, setUpdatedDiscName] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loading, setLoading] = useState(true);  // Add this
  const auth = getAuth();
  const db = getFirestore(app);
  const storage = getStorage(app); // Initialize Firebase storage
  const [showContactModal, setShowContactModal] = useState(false); // State for modal
  const handleShowModal = () => setShowContactModal(true);
  const handleCloseModal = () => setShowContactModal(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, [auth]);

  const handleSignOut = () => {
    auth.signOut().then(() => {
      setUser(null);
      localStorage.clear();
    }).catch((error) => {
      console.error('Error signing out:', error);
    });
  };

  const calculateHoursRemaining = (finishTime) => {
    const currentTime = new Date();
    const finishDate = new Date(finishTime.seconds * 1000);
    const diffInMs = finishDate - currentTime;
    const diffInHours = Math.ceil(diffInMs / (1000 * 60 * 60)); // Convert milliseconds to hours
    return diffInHours > 0 ? diffInHours : 0;
  };

  const uploadImage = async (discId, imageType, file, setImageProgress) => {
    try {
      // Compress the image before uploading
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1024,
        useWebWorker: true,
      };
  
      const compressedFile = await imageCompression(file, options);
  
      // Start progress
      setIsUploading(true);
      setImageProgress(0);
      setUploadProgress(0); // Initialize overall progress to 0
  
      // Get the MIME type of the compressed file
      const mimeType = compressedFile.type;
  
      // Get the presigned URL for S3 upload
      const presignedUrl = await getPresignedUrl(`${user.uid}/${discId}/${imageType}.jpg`, mimeType, discId, user);
  
      // Upload the compressed image to S3 with progress tracking
      const uploadTask = uploadToS3(compressedFile, presignedUrl, (progress) => {
        setImageProgress(progress); // Update image-specific progress
        setUploadProgress(progress); // Update overall progress
      });
  
      // Wait for the upload to complete
      await uploadTask;
  
      // Get the public S3 URL (strip out the query params)
      const s3Url = presignedUrl.split('?')[0];
  
      // Update Firestore document with the new S3 URL and expiration date
      await updateDoc(doc(db, 'Discs', discId), {
        [imageType]: {
          url: s3Url,
          expiration: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString(),
        },
      });
  
      // Generate new pre-signed URLs for the disc to ensure the UI has up-to-date URLs
      const presignedUrlResult = await generatePresignedUrlForDisc(discId);
  
      // Reset state after upload
      setIsUploading(false);
      setImageProgress(0);
      setUploadProgress(0);
  
      // Refetch discs if pre-signed URLs are successfully generated
      if (presignedUrlResult && presignedUrlResult.data.success) {
        await fetchDiscs(3, true); // Refetch with `true` to ensure URLs are up to date
      }
    } catch (error) {
      console.error('Error uploading image to S3:', error);
      setIsUploading(false);
      setImageProgress(0);
      setUploadProgress(0);
    }
  };
  

  const fetchUserDiscCount = async () => {
    if (user) {
      const userRef = doc(db, 'Users', user.uid);
      const userDoc = await getDoc(userRef);
      return userDoc.exists() ? userDoc.data().discCount : 0;
    }
    return 0;
  };

  const fetchDiscs = async (retryCount = 3, refreshAfterUpdate = false) => {
    setLoading(true);
  
    try {
      const cachedDiscs = JSON.parse(localStorage.getItem('discs'));
      const cachedDiscCount = localStorage.getItem('cachedDiscCount');
      const lastFetched = localStorage.getItem('lastFetched');
      const currentTime = Date.now();
  
      const serverDiscCount = await fetchUserDiscCount();
  
      if (!refreshAfterUpdate && cachedDiscs && cachedDiscCount === serverDiscCount && currentTime - lastFetched < 86400000) {
        setDiscs(cachedDiscs);
      } else {
        if (user) {
          const discsRef = collection(db, 'Discs');
          const q = query(discsRef, where('userid', '==', user.uid));
          const querySnapshot = await getDocs(q);
          const fetchedDiscs = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  
          const updatedDiscs = await Promise.all(
            fetchedDiscs.map(async (disc) => {
              let needsUpdate = false;
  
              // Check for each image field if pre-signed URL needs an update
              ['beforeImage', 'patternImage'].forEach((field) => {
                if (disc[field]) {
                  const expiration = disc[field].expiration;  // Access the expiration inside the image map
                  if (!expiration || new Date(expiration) <= new Date()) {
                    //console.log(`Presigned URL needed for ${field} of disc ${disc.id}: Expiration is either missing or expired (Expiration: ${expiration})`);
                    needsUpdate = true;
                  } else {
                    //console.log(`No presigned URL needed for ${field} of disc ${disc.id}: Expiration is valid until ${expiration}`);
                  }
                }
              });
  
              ['stencilImage', 'finishedImage'].forEach((field) => {
                if (disc[field] !== null && disc[field]) {
                  const expiration = disc[field].expiration;  // Access the expiration inside the image map
                  if (!expiration || new Date(expiration) <= new Date()) {
                    //console.log(`Presigned URL needed for ${field} of disc ${disc.id}: Expiration is either missing or expired (Expiration: ${expiration})`);
                    needsUpdate = true;
                  } else {
                    //console.log(`No presigned URL needed for ${field} of disc ${disc.id}: Expiration is valid until ${expiration}`);
                  }
                } else {
                  //console.log(`Skipping presigned URL for ${field} of disc ${disc.id}: Field is null`);
                }
              });
  
              if (needsUpdate) {
                //console.log(`Getting presigned URL for disc: ${disc.id}`);
  
                try {
                  const result = await generatePresignedUrlForDisc(disc.id);
  
                  if (result && result.success) {
                    //console.log(`Pre-signed URLs updated for disc: ${disc.id}`);
                    return { ...disc, ...result.urls };
                  } else {
                    //console.log(`Failed to update pre-signed URLs for disc: ${disc.id}`);
                    //console.log(`Response from generatePresignedUrlForDisc: ${JSON.stringify(result)}`);
                  }
                } catch (error) {
                  console.error(`Error generating presigned URL for disc: ${disc.id}`, {
                    errorMessage: error.message,
                    discData: JSON.stringify(disc),
                    result: error,
                  });
                }
              }
              return disc;
            })
          );
  
          const sortedDiscs = updatedDiscs.sort((a, b) => {
            const currentTime = Date.now();
            const aHasFinishedImage = !!a.finishedImage;
            const bHasFinishedImage = !!b.finishedImage;
  
            if (!aHasFinishedImage && bHasFinishedImage) return -1;
            if (aHasFinishedImage && !bHasFinishedImage) return 1;
  
            const aTimeRemaining = !a.isFinished ? a.finishedTime.seconds * 1000 - currentTime : null;
            const bTimeRemaining = !b.isFinished ? b.finishedTime.seconds * 1000 - currentTime : null;
  
            if (!a.isFinished && !b.isFinished) return aTimeRemaining - bTimeRemaining;
            if (!a.isFinished && b.isFinished) return -1;
            if (a.isFinished && !b.isFinished) return 1;
  
            const aFinishedTime = a.finishedTime.toMillis();
            const bFinishedTime = b.finishedTime.toMillis();
            return bFinishedTime - aFinishedTime;
          });
  
          setDiscs(sortedDiscs);
          localStorage.setItem('discs', JSON.stringify(sortedDiscs));
          localStorage.setItem('cachedDiscCount', serverDiscCount);
          localStorage.setItem('lastFetched', currentTime);
        }
      }
    } catch (error) {
      console.error('Error fetching discs:', error);
  
      // Retry fetching if necessary
      if (retryCount > 0) {
        setTimeout(() => fetchDiscs(retryCount - 1), 1000); // Retry after 1 second
      }
    } finally {
      setLoading(false);
    }
  };
  
  // Function to generate pre-signed URL for a specific disc and fetch discs again after updating
  const generatePresignedUrlForDisc = async (discId) => {
    const functions = getFunctions(app); // Use Firebase Functions
    const generateUrls = httpsCallable(functions, 'generatePresignedUrlsForDisc');
    const result = await generateUrls({ discId }); // Pass the discId to the function
  
    // If successful, refetch discs to ensure URLs are up-to-date before rendering
    if (result && result.data.success) {
      //console.log(`Pre-signed URLs generated for disc: ${discId}`);
      await fetchDiscs(3, true); // Pass `true` to refresh discs after update
    }
  
    return result;
  };
  
  
  

  useEffect(() => {
    if (user) {
      fetchDiscs();
    }
  }, [user]);

  const handleDeleteDisc = async (discId) => {
    if (window.confirm('Are you sure you want to delete this disc? This action cannot be undone.')) {
        try {
            // Reference to the disc in Firestore
            const discRef = doc(db, 'Discs', discId);

            // Fetch the disc data (to get image URLs)
            const discSnap = await getDoc(discRef);

            if (discSnap.exists()) {
                const discData = discSnap.data();

                // S3 object key is constructed as: userId/discId/imageName.jpg
                const s3Keys = [];

                // Add image paths if they exist
                if (discData.beforeImage) s3Keys.push(`${user.uid}/${discId}/beforeImage.jpg`);
                if (discData.patternImage) s3Keys.push(`${user.uid}/${discId}/patternImage.jpg`);
                if (discData.finishedImage) s3Keys.push(`${user.uid}/${discId}/finishedImage.jpg`);
                if (discData.stencilImage) s3Keys.push(`${user.uid}/${discId}/stencilImage.jpg`);

                // Make requests to delete each image from S3
                const deletePromises = s3Keys.map(async (key) => {
                    const response = await fetch('https://us-central1-disc-dye-tracker.cloudfunctions.net/deleteFromS3', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ key }),  // Pass the S3 object key to the backend function
                    });

                    if (!response.ok) {
                        //console.error(`Error deleting image ${key} from S3:`, await response.text());
                    } else {
                        //console.log(`Successfully deleted ${key} from S3`);
                    }
                });

                // Wait for all S3 deletions to complete
                await Promise.all(deletePromises);

                // Finally, delete the Firestore document
                await deleteDoc(discRef);

                // Optionally refresh the disc list
                fetchDiscs();

                alert('Disc and all associated images were successfully deleted.');
            } else {
                //console.error('No such disc found.');
            }
        } catch (error) {
            //console.error('Error deleting disc:', error);
        }
    }
};

  const handleEditDiscName = (discId, currentName) => {
    setEditingDiscId(discId);
    setUpdatedDiscName(currentName || '');
  };

  const handleSaveDiscName = async (discId) => {
    if (updatedDiscName !== '') {
      try {
        const discRef = doc(db, 'Discs', discId);
        await updateDoc(discRef, { discName: updatedDiscName });
        fetchDiscs();
        setEditingDiscId(null);
      } catch (error) {
        console.error('Error updating disc name:', error);
      }
    }
  };

  const handleEditNotes = (discId, currentNotes) => {
    setEditingNotesId(discId);
    setUpdatedNotes(currentNotes || '');
  };

  const handleSaveNotes = async (discId) => {
    if (updatedNotes !== '') {
      try {
        const discRef = doc(db, 'Discs', discId);
        await updateDoc(discRef, { notes: updatedNotes });
        fetchDiscs();
        setEditingNotesId(null);
      } catch (error) {
        console.error('Error updating notes:', error);
      }
    }
  };

  const uploadFinishedImage = async (discId, file, setFinishedImageProgress) => {
    try {
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1024,
            useWebWorker: true
        };

        const compressedFile = await imageCompression(file, options);
        setIsUploading(true); // This will control the overall uploading state
        setFinishedImageProgress(0); // Initialize progress to 0

        const mimeType = compressedFile.type;
        const presignedUrl = await getPresignedUrlFinishedImage('finishedImage.jpg', mimeType, discId, user);

        const uploadTask = uploadToS3(compressedFile, presignedUrl, (progress) => {
            setFinishedImageProgress(progress); // Update finished image progress
        });

        await uploadTask;

        const s3Url = presignedUrl.split('?')[0];
        await updateDoc(doc(db, 'Discs', discId), {
            finishedImage: {
                url: s3Url,
                expiration: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString(),
            },
            isFinished: true
        });

        const presignedUrlResult = await generatePresignedUrlForDisc(discId);

        setIsUploading(false);
        setFinishedImageProgress(0); // Reset progress after completion

        if (presignedUrlResult && presignedUrlResult.data.success) {
            await fetchDiscs(3, true);
        }

    } catch (error) {
        console.error('Error uploading finished image to S3:', error);
        setIsUploading(false);
        setFinishedImageProgress(0); // Reset progress on error
    }
};

  
  
  const getPresignedUrl = async (imageName, mimeType, discId, user) => {
    // Get Firebase authentication token (make sure the user is authenticated)
    const token = await user.getIdToken();  // This assumes that the `user` object is already authenticated
  
    try {
      // Make a request to your Firebase Function to get the presigned URL
      const response = await fetch(
        `https://us-central1-disc-dye-tracker.cloudfunctions.net/getUploadUrl?fileName=${imageName}&mimeType=${mimeType}&userId=${user.uid}&discId=${discId}`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,  // Include Firebase Auth token in the header
          },
        }
      );
  
      if (!response.ok) {
        const errorResponse = await response.text();
        //console.error('Error from getUploadUrl:', errorResponse);  // Log any error returned from the function
        throw new Error(`Failed to get presigned URL: ${response.status}`);  // Throw error if the request fails
      }
  
      const data = await response.json();  // Parse the JSON response
      //console.log("Presigned URL:", data.uploadUrl);  // Log the presigned URL for debugging
      return data.uploadUrl;  // Return the presigned URL for the S3 upload
    } catch (error) {
      //console.error('Error fetching presigned URL:', error);  // Catch any errors from the API call
      throw error;
    }
  };
  

  const getPresignedUrlFinishedImage = async (imageName, mimeType, discId, user) => {
    // Get Firebase authentication token (make sure the user is authenticated)
    const token = await user.getIdToken();  // This assumes that the `user` object is already authenticated
  
    try {
      // Make a request to your Firebase Function to get the presigned URL
      const response = await fetch(
        `https://us-central1-disc-dye-tracker.cloudfunctions.net/getUploadUrlFinishedImage?fileName=${imageName}&mimeType=${mimeType}&userId=${user.uid}&discId=${discId}`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,  // Include Firebase Auth token in the header
          },
        }
      );
  
      if (!response.ok) {
        const errorResponse = await response.text();
        //console.error('Error from getUploadUrl:', errorResponse);  // Log any error returned from the function
        throw new Error(`Failed to get presigned URL: ${response.status}`);  // Throw error if the request fails
      }
  
      const data = await response.json();  // Parse the JSON response
      //console.log("Presigned URL:", data.uploadUrl);  // Log the presigned URL for debugging
      return data.uploadUrl;  // Return the presigned URL for the S3 upload
    } catch (error) {
      //console.error('Error fetching presigned URL:', error);  // Catch any errors from the API call
      throw error;
    }
  };
  
  const uploadToS3 = async (file, presignedUrl) => {
    try {
      //console.log('Starting file upload to S3:', file.name, file.type);
  
      // Compress the file to reduce its size (using an image compression library)
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 1,  // Maximum size of 1MB
        maxWidthOrHeight: 1024,  // Max dimension of 1024px
      });
  
      //console.log('Compressed file size:', compressedFile.size);
  
      // Upload the compressed file to S3 using the presigned URL
      const response = await fetch(presignedUrl, {
        method: 'PUT',  // PUT method is required by S3 for presigned URLs
        body: compressedFile,  // Pass the compressed image as the request body
        headers: {
          'Content-Type': file.type,  // Content-Type is required by S3 (use the original file type)
        },
      });
  
      // Check if the response is successful
      if (!response.ok) {
        const errorResponse = await response.text();
        console.error('Error uploading to S3:', errorResponse);  // Log any error responses from S3
        throw new Error('Image upload failed');  // Throw an error if the upload fails
      }
  
      // If successful, log and return the S3 file URL without the query parameters
      const s3Url = presignedUrl.split('?')[0];  // The S3 file URL
      //console.log('Successfully uploaded to S3:', s3Url);
      return s3Url;  // Return the S3 URL for further use
    } catch (error) {
      //console.error('Error uploading image:', error);  // Catch and log any upload errors
      throw error;  // Propagate the error upwards
    }
  };
  
    return (
      <Router>
        <div className="App">
          <header className="App-header">
            {user && (
              <>
                <div className="text-center mt-2">
                  <h1 className="display-6 text-white">Disc Golf Dye Tracker</h1>
                </div>
                <nav className="navbar navbar-dark">
                  <div className="container-fluid">
                    <ul className="navbar-nav ms-auto d-flex flex-row">
                      <li className="nav-item">
                        <Link to="/home" className="nav-link btn btn-secondary mx-2">Home</Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/add-disc" className="nav-link btn btn-secondary mx-2">Add Disc</Link>
                      </li>
                      <li className="nav-item">
                      <button className="nav-link btn btn-secondary mx-2" onClick={handleShowModal}>Contact</button>
                    </li>
                    <li className="nav-item">
                      <button
                        className="nav-link btn btn-danger mx-2"
                        onClick={() => {
                          const auth = getAuth();
                          auth.signOut()
                            .then(() => {
                              setUser(null);  // Clear the user state
                              localStorage.clear();  // Clear the local storage
    
                              window.location.replace("/");  // Redirect to login page
                            })
                            .catch((error) => {
                              console.error('Error during sign out:', error);
                            });
                        }}
                      >
                        Logout
                      </button>
                    </li>

                    </ul>
                  </div>
                </nav>
              </>
            )}
    
            <Routes>
              <Route
                path="/"
                element={user ? <Navigate to="/home" /> : <Login onUserSignIn={setUser} />}
              />
              <Route
                path="/home"
                element={
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      minHeight: '100vh',
                      maxHeight: '80vh',
                      overflowY: 'auto',
                    }}
                  >
                    {loading ? (  // Add loading state check
                      <p>Loading discs...</p>
                    ) : discs.length > 0 ? (
                      discs.map((disc) => (
                        <DiscItem
                          key={disc.id}
                          disc={disc}
                          isFinished={disc.isFinished}
                          hoursRemaining={calculateHoursRemaining(disc.finishedTime)}
                          handleDeleteDisc={handleDeleteDisc}
                          handleEditDiscName={handleEditDiscName}
                          handleSaveDiscName={handleSaveDiscName}
                          editingDiscId={editingDiscId}
                          updatedDiscName={updatedDiscName}
                          setEditingDiscId={setEditingDiscId}
                          setUpdatedDiscName={setUpdatedDiscName}
                          handleEditNotes={handleEditNotes}
                          handleSaveNotes={handleSaveNotes}
                          editingNotesId={editingNotesId}
                          updatedNotes={updatedNotes}
                          setEditingNotesId={setEditingNotesId}
                          setUpdatedNotes={setUpdatedNotes}
                          uploadFinishedImage={uploadFinishedImage}
                          uploadImage={uploadImage}
                          setUploadProgress={setUploadProgress}
                          isUploading={isUploading}
                          uploadProgress={uploadProgress}
                          getPresignedUrl={getPresignedUrl}
                          uploadToS3={uploadToS3}
                          user={user}
                        />
                      ))
                    ) : (
                      <>
                          <h2>No discs yet</h2>
                          <Link to="/add-disc" className="btn btn-success mt-4">
                            Add Your First Disc
                          </Link>
                        </>
                    )}
                  </div>
                }
              />
              <Route
                path="/add-disc"
                element={<DiscForm user={user} fetchDiscs={fetchDiscs} 
                getPresignedUrl={getPresignedUrl}
                uploadToS3={uploadToS3}
                uploadFinishedImage={uploadFinishedImage} />}
              />
              <Route path="*" element={<Navigate to={user ? "/home" : "/"} />} />
            </Routes>
          </header>

          {/* Modal for Contact Us and Buy Me a Disc */}
          <Modal 
            show={showContactModal} 
            onHide={handleCloseModal} 
            centered
            dialogClassName="custom-modal" // Custom class for the entire modal
          >
            <Modal.Header 
                closeButton 
                style={{ 
                  backgroundColor: '#282c34', 
                  color: 'white', 
                  borderTopLeftRadius: '15px', 
                  borderTopRightRadius: '15px' 
                }}
              >
                <Modal.Title>Contact</Modal.Title>
              </Modal.Header>
            <Modal.Body style={{ backgroundColor: '#282c34', color: 'white', padding: '20px' }}>
              <p>Feel free to reach out to me with feedback, questions, or report any bugs:</p>
              <p><strong>Email:</strong> <a href="mailto:Topcitysoftware@gmail.com" style={{ color: '#66b2ff' }}>Topcitysoftware@gmail.com</a></p>
              <p><strong>LinkedIn:</strong> <a href="https://www.linkedin.com/in/brett-kulp/" target="_blank" rel="noopener noreferrer" style={{ color: '#66b2ff' }}>https://www.linkedin.com/in/brett-kulp/</a></p>
              <p>Buy me a disc to help support my work!</p>
              <p><strong>Venmo:</strong> <a href="https://venmo.com/BrettTKulp" target="_blank" rel="noopener noreferrer" style={{ color: '#66b2ff' }}>https://venmo.com/BrettTKulp</a></p>
              <p><strong>My other work:</strong> <a href="https://basheventsapp.com" target="_blank" rel="noopener noreferrer" style={{ color: '#66b2ff' }}>https://basheventsapp.com</a></p>
            </Modal.Body>
            <Modal.Footer style={{ backgroundColor: '#282c34', justifyContent: 'center', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px' }}>
              <Button onClick={handleCloseModal} style={{ backgroundColor: '#007bff', color: 'white', borderRadius: '50px', padding: '10px 30px', border: 'none' }}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>



        </div>
      </Router>
    );    
}

export default App;
